/* Animations */
@keyframes wave {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.wave-text {
  display: inline-block;
  animation: wave .5s ease-in-out;
}

/* App CSS */
html, body {
  height: 100dvh;
  width: 100dvw;
  max-height: 100dvh;
  max-width: 100dvw;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: "Montserrat", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
  overflow: auto;
  height: 100dvh;
  width: 100dvw;
}

.App {
  text-align: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  height: 100%;
}

/* Game CSS */
.header {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-height: 300px;
  padding: 12px 0 18px 0;
  border-bottom: 1px solid gray;
}

.header .streak {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  left: 10px;
  font-size: 14px;
  padding: 2px 7px;
  /* cursor: pointer; */
  display: flex;
  align-items: center;
}

.header .streak .total-score {
  margin-left: 4px;
  /* padding-top: 1px; */
}

.header .help {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  right: 20px;
  font-size: 14px;
  padding: 2px 7px;
  border: 1px solid gray;
  color: gray;
  border-radius: 50%;
  cursor: pointer;
}

.header .help.secret-icon {
  padding: 2px 9px;
}

.modal-close {
  padding: 7px;
  border-radius: 50%;
  background-color: inherit;
  cursor: pointer;
  color: gray;
  width: fit-content;
  position: absolute;
  top: 10px;
  right: 10px;
}

.modal-body div {
  margin: 12px 0;
}

.modal-body .modal-footer {
  margin-top: 35px;
  border-top: 1px solid rgb(204, 204, 204);
  padding-top: 20px;
  font-size: 0.8rem;
  font-style: italic;
  color: rgb(133, 133, 133);
  line-height: 1.3;
}

.flex-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 50vh;
  width: 100%;
}

.flex-container.is-game-over {
  justify-content: space-between;
  margin-top: 30px;
}

.flex-container-2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 130px;
  width: 100%;
}

.stars-container {
  position: relative;
}

.stars-container .minus-one {
  position: absolute;
  right: -20px;
  top: 3px;
}

.hidden {
  opacity: 0;
  transition: opacity 0.5s linear;
}

.stars {
  display: flex;
  margin-bottom: 18px;
}

.stars-game-over {
  display: flex;
  margin-bottom: 0;
}

.board {
  position: relative;
  font-size: 8vw;
  width: 100%;
}

.clue {
  letter-spacing: 2vw;
}

.board input {
  font-family: inherit;
  font-size: inherit;
  border: none;
  border-bottom: 2px solid #000;
  padding: 0;
  max-width: 10vw;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 0;
  transition: all 0.3s;
}

.board input.correct {
  color: #17866a;
  border-bottom: 2px solid #17866a;
  background-color: rgba(23, 134, 106, 0.15);
}

.board input.incorrect {
  color: rgba(255, 0, 0);
  border-bottom: 2px solid rgba(255, 0, 0);
  background-color: rgba(255, 0, 0, 0.15);
}

input:focus {
  outline: none;
}

.buttonBar {
  height: 40px;;
}

.buttonBar.vertical {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonBar.vertical button {
  margin-top: 20px;
  width: auto;
  padding: 8px 20px;
}

.buttonBar button {
  font-family: inherit;
  padding: 7px 18px;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background-color: #17866a;
  outline: 1px solid black;
  border-radius: 50px;
  transition: all 0.3s;
  border: none;
  width: 105px;
}

.buttonBar button.disabled {
  font-family: inherit;
  cursor: default;
  outline: 1px solid #abaaaa;
  color: #abaaaa;
  background-color: rgba(0, 0, 0, 0.05);
}

.buttonBar button.disabled:focus,
.buttonBar button.disabled:active,
.buttonBar button.disabled:hover {
  font-family: inherit;
  cursor: default;
  outline: 1px solid #abaaaa;
  color: #abaaaa;
  background-color: rgba(0, 0, 0, 0.05);
}

.buttonBar button:hover {
  background-color: #136752;
}

.buttonBar .hint {
  margin-right: 16px;
  background-color: #000;
}

.buttonBar .hint:hover {
  background-color: #000;
}

.keyboard {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 650px;
  text-transform: uppercase;
}

.simple-keyboard.hg-layout-default .removed {
  background-color: #c4c4c4 !important;
  pointer-events: none;
}

.clue-subtext {
  position: absolute;
  bottom: -85%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  font-style: italic;
  width: 100%;
}

.clue-subtext.hint {
  animation: fadeIn 1.5s;
}

.countdown {
  font-size: 18px;
}

.countdown .time {
  font-weight: bold;
}

.game-over {
  width: 100%;
  flex-direction: column;
  display: flex;
  height: 20%;
  justify-content: space-evenly;
}

.game-over-message {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
}

.game-over-score {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
}

.your-score {
  margin-right: 6px;
}

/* Forms */
.add-clue-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.add-clue-form .fields {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.add-clue-form .field {
  margin: 4px 0;
  display: flex;
  align-items: center;
}

.add-clue-form .field input {
  width: 200px;
  max-width: 200px;
  height: 24px;
  padding: 4px;
  font-family: inherit;
  font-size: inherit;
  margin-left: 8px;
  text-align: start;
}

#clueDate {
  text-align: start;
  background-color: #fff;
  color: inherit;
  outline: none;
  border: 1px solid gray;
  -webkit-appearance: none;
  appearance: none;
}

input::-webkit-date-and-time-value {
  text-align: start;
}

/* Media Queries */
@media only screen and (min-width: 550px) {
  .board {
    font-size: 40px;
  }

  .clue {
    letter-spacing: 16px;
  }

  input {
    max-width: 60px;
  }
}